import en from '@/i18n/en.json';
import de from '@/i18n/de.json';
import pl from '@/i18n/pl.json';

import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
  locale: 'en',
  messages: {en, de, pl }
})

export default i18n;