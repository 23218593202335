import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ActivationPage_wrapper" }
const _hoisted_2 = { class: "ui-txt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_switch = _resolveComponent("language-switch")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_dev_box = _resolveComponent("dev-box")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "ActivationPage",
    class: "ion-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "ActivationPage_content" }, {
        default: _withCtx(() => [
          _createVNode(_component_language_switch, { class: "ActivationPage_LanguageSwitch" }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('AwaitingActivation.awaitingActivation')), 1),
            _createElementVNode("span", {
              class: "AwaitingActivation_logoutBtn ui-txt-md",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogoutClick && _ctx.handleLogoutClick(...args)))
            }, _toDisplayString(_ctx.$t('SideMenu.logout')), 1)
          ])
        ]),
        _: 1
      }),
      false
        ? (_openBlock(), _createBlock(_component_dev_box, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.state), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}