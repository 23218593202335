class LocalStorageService {
  setLanguage = (language: string) => {
    localStorage.setItem('PARKIT_LANGUAGE', language);
  }

  getLanguage = (): string | null => {
    return localStorage.getItem('PARKIT_LANGUAGE');
  }

  setUserData(userData: any) {
    if (userData) {
      localStorage.setItem('PARKIT_USER_DATA', JSON.stringify(userData));
    } else {
      localStorage.removeItem('PARKIT_USER_DATA');
    }
  }

  getUserData(): any {
    const userData = localStorage.getItem('PARKIT_USER_DATA');
    return userData
      ? JSON.parse(userData)
      : null;
  }
}

export default new LocalStorageService();
