<template>
  <ion-menu
    class="SideMenu"
    side="end"
    type="overlay"
    menu-id="mainMenu"
    content-id="main"
  >
    <div class="SideMenu_content">
      <ion-header class="SideMenu_header">
        <ion-toolbar>
          <p class="SideMenu_title">{{$t('SideMenu.menuTitle')}}  {{ store.state.user.login }}</p>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="SideMenu_itemList">

          <ion-item
          class="SideMenu_item"
            button
            :detail="false"
            @click="handleHomeClick"
          >
            <ion-icon :icon="homeOutline" slot="start"></ion-icon>
            <ion-label>{{$t('SideMenu.home')}}</ion-label>
          </ion-item>

          <ion-item
            class="SideMenu_item is-admin"
            v-if="isAdminEnabled"
            button
            :detail="false"
            @click="handleAdminClick"
          >
            <ion-icon :icon="newspaperOutline" slot="start"></ion-icon>
            <ion-label>{{$t('SideMenu.admin')}} <span class="SideMenu_desktopOnly">({{ $t('SideMenu.desktopOnly') }})</span></ion-label>
          </ion-item>

          <ion-item
            class="SideMenu_item"
            button
            :detail="false"
            @click="handleLogoutClick"
          >
            <ion-icon :icon="logOutOutline" slot="start"></ion-icon>
            <ion-label>{{$t('SideMenu.logout')}}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </div>
  </ion-menu>
</template>

<script lang="ts">
import {
  IonMenu,
  IonToolbar,
  IonHeader,
  IonItem,
  IonList,
  IonContent,
  IonIcon,
  IonLabel,
  menuController,
} from '@ionic/vue';
import { computed, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import { logOutOutline, newspaperOutline, homeOutline } from 'ionicons/icons';
import actions from '../store/actions';
import router from '@/router/index';

export default defineComponent({
  name: 'SideMenu',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonLabel,
  },
  setup() {
    const store = useStore();
    const handleLogoutClick = () => {
      actions.logout();
    };
    const handleAdminClick = () => {
      router.push('/admin');
    };
    const handleHomeClick = () => {
      router.push('/home');
    }

    // auto close menu when route changes
    watch(router.currentRoute, () => {
      setTimeout(() => {
        menuController.close('mainMenu');
      }, 600)
    });

    return {
      store,
      logOutOutline,
      newspaperOutline,
      homeOutline,
      handleLogoutClick,
      handleAdminClick,
      handleHomeClick,
      isAdminEnabled: computed(() => store.getters.isAdmin),
    }
  },
});
</script>
<style lang="scss">
.SideMenu {
  --background: rgba(10, 19, 31, 0.85);
  color: #fff;
  *,
  .item.sc-ion-label-ios-h,
  .item .sc-ion-label-ios-h {
    color: #fff;
  }
}

.SideMenu_title {
  text-align: center;
  font-weight: 500;
}

.SideMenu_header {
  ion-toolbar {
    --background: transparent;
  }
}
.SideMenu_content {
  height: 100%;
}

.SideMenu_itemList {
  --ion-item-border-color: rgba(255, 255, 255, 0.33);
  --ion-item-background: transparent;
}

.SideMenu_item {
  transition: all .15s;
  &:last-child {
    --ion-item-border-color: transparent;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  &.is-admin {
    @media screen and (max-width: 960px) {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
.SideMenu_desktopOnly {
  display: none;
  @media screen and (max-width: 960px) {
    display: inline;
  }
}
</style>
