<template>
  <ion-card
    :disabled="isDisabled"
    :class="{
      'DayCard': true,
      'is-today': isToday,
      'is-reserved': isReservedByMe,
    }"
  >
    <ion-card-content>
      <div class="DayCard_cardContent">
        <!-- card left -->
        <div class="DayCard_leftSide">
          <span class="DayCard_dayName ui-txt-lg">
            {{ formattedDayOfWeek }}
          </span>
          <span class="DayCard_cardDate ui-txt-sm">{{ formattedDate }}</span>
        </div>

        <!-- card right -->
        <div class="DayCard_rightSide">
          <div class="DayCard_today">
            <span v-if="isToday" class="DayCard_todayLabel ui-txt-md">
              {{ $t('DayCard.today') }}
            </span>
          </div>
          <div v-if="isReservedByMe" class="DayCard_placeDetails ui-txt-xs">
            <span class="DayCard_parkingSpace">{{ $t('DayCard.space') }}: <strong class="ui-txt-sm">{{ day.reservations[0].parkingSpace.number }}</strong></span>
            <span class="DayCard_floorNumber">{{ $t('DayCard.floor') }}: <strong  class="ui-txt-sm">{{ day.reservations[0].parkingSpace.floor }}</strong></span>
          </div>
          <template v-if="!isReservedByMe && !isGuest">
            <div class="DayCard_diagonalBox"></div>
            <div class="DayCard_rightContent">
              <span class="DayCard_freeSpaces ui-txt-md">
                {{ $t('DayCard.free') }}: {{ day.freeParkingSpacesCount }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue';
import {
  IonCard,
  IonCardContent,
} from '@ionic/vue';
import { IDay } from '../store/interfaces';
import moment from 'moment';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'DayCard',
  components: {
    IonCard,
    IonCardContent,
  },
  props: {
    day: {
      type: Object as () => IDay,
      required: true,
    }
  },
  setup(props) {
    const store = useStore();
    const { day } = toRefs(props);
    const formattedDayOfWeek = computed(() => day.value.date.locale(store.state.user.language).format('dddd'));
    const formattedDate = computed(() => day.value.date.locale(store.state.user.language).format('DD.MM'));
    const startOfToday = moment().startOf('day');
    const userLogin = store.state.user.session.profile.login;
    const isToday = computed(() => {
      const currentDay = moment(day.value.date.toISOString()).startOf('day');
      return startOfToday.isSame(currentDay);
    });

    const isPastDay = computed(() => startOfToday.isAfter(day.value.date.toISOString()));
    //TODO
    const twoWeeksLater = moment().clone().add(14, 'days').endOf('day');
    const almostTwoWeeksDaysLater = moment().clone().add(15, 'days').endOf('day');

    const isAfterTwoWeeks = computed(() => {
      const isLastDayAndAfter20Test = day.value.date.isSame(almostTwoWeeksDaysLater, 'day') && moment().hour() >= 20
      if(isLastDayAndAfter20Test) return false
      else return day.value.date.isAfter(twoWeeksLater)
    });
    const hasFreeSpaces = computed(() => day.value.freeParkingSpacesCount > 0);
    const isReservedByMe = computed(() => {
      const myReservation = (day.value as IDay).reservations.find(
        (r: any) => {
          return r.bookingUserId.toLowerCase() === userLogin.toLowerCase()
        }
      );
      return !!myReservation;
    });

    const isDisabled = computed(() => {
      return isPastDay.value || !hasFreeSpaces.value && !isReservedByMe.value || isAfterTwoWeeks.value && !isReservedByMe.value;
    });

    return {
      formattedDayOfWeek,
      formattedDate,
      startOfToday,
      isToday,
      isReservedByMe,
      isGuest: computed(() => store.getters.isGuest),
      isDisabled
    }
  }
});
</script>

<style lang="scss">
@import '/src/common/helpers';

$primaryColor: #0e2d63;
.DayCard {
  margin: 0;
  margin-bottom: calc(1vw + 10px);
  border: solid 2px white;
  backdrop-filter: blur(6px);
  border-radius: 100px;
  transition: all 0.05s;
  background: transparent;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    // margin-top: 1.5vw;
  }

  &:hover:not(.is-today) {
    background: rgba(255, 255, 255, 0.075);
  }

  &.is-reserved {
    background: rgba(187, 255, 0, 0.3);
    border-color: rgba(200, 255, 0, 0.7);

    &:hover {
      background: rgba(195,255,25,0.35);
    }
  }

  &.is-today {
    .DayCard_cardDate {
      @media screen and (max-width: 455px) {
        display: none;
      }
    }
  }

  ion-card-content {
    padding: 0;
  }
}

.DayCard_placeDetails {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  strong {
    margin: 0;
    line-height: 0;
  }
}

.DayCard_cardContent {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.DayCard_leftSide {
  flex-grow: 1;
  display: flex;
  align-items: baseline;
}

.DayCard_rightSide {
  display: flex;
  align-items: stretch;
}

.DayCard_today {
  display: flex;
  align-items: center;
  margin-right: calc(1vw + 15px);
  span {
    font-weight: 500;
  }
}

.DayCard_diagonalBox {
  height: 100%;
  width: 20px;
  position: relative;
  overflow: hidden;
  margin-left: calc(-15px - 1vw);
  &:before {
    display: block;
    content: '';
    background: rgba(2, 19, 49, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skewX(-14deg);
    right: -10px;
  }
}

.DayCard_rightContent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(2, 19, 49, 0.4);
}

.DayCard_dayName {
  display: inline-block;
  color: #fff;
  font-weight: 500;
  padding: 10px 0 10px 10px;
  margin-left: 2.25vw;
  min-width: uiscale(135px, 270px);
}

.DayCard_freeSpaces {
  font-weight: 500;
  color: #fff;
  margin-right: calc(1vw + 20px);
  margin-left: 1vw;
  white-space: nowrap;
}

.DayCard_cardDate {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 5px;
}

.DayCard_todayLabel {
  color: #fff;
}
</style>
