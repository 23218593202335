<template>
  <div class="TopBar">
    <ion-toolbar>
      <!-- header left -->
      <div slot="start">
        <template v-if="isReservationPage || isAdminPage">
          <div
            @click="$router.back()"
            class="TopBar_back ui-btn is-sm"
          >
            <ion-icon :src="chevronBackOutline"></ion-icon>
            <span class="ui-btn_label">{{ $t('ReservationPage.goBack') }}</span>
          </div>
        </template>
        <div v-else class="TopBar_appTitle" @click="handleLogoClick">
          <img src="/assets/logo/app-logo6.png" class="TopBar_appLogo">
          <span class="TopBar_appName">Park.it</span>
        </div>
      </div>

      <!-- header center -->
      <ion-title size="medium" slot="start" class="TopBar_title">
        <h2
          v-if="isReservationPage"
          class="TopBar_pageTitle ui-txt-md"
        >
          <template v-if="isCreatingReservation">
            {{ $t('TopBar.newReservation') }}
          </template>
          <template v-else>
            {{ $t('TopBar.yourReservation') }}
          </template>
        </h2>
      </ion-title>

      <!-- header right -->
      <ion-buttons slot="end">
        <language-switch class="TopBar_LanguageSwitch" />
        <ion-icon class="TopBar_drawerIcon" :icon="menu" @click="handleOpenMenuClick" />
      </ion-buttons>
    </ion-toolbar>
  </div>
</template>

<script lang="ts">
import {
  IonIcon,
  IonToolbar,
  IonButtons,
  menuController,
  IonTitle,
} from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { menu } from 'ionicons/icons';
import router from '@/router/index';
import LanguageSwitch from '@/components/LanguageSwitch.vue';
import store from '@/store/store';
import chevronBackOutline from 'ionicons/dist/svg/chevron-back-outline.svg';

export default defineComponent({
  name: 'TopBar',
  components: {
    IonToolbar,
    IonIcon,
    IonButtons,
    LanguageSwitch,
    IonTitle,
  },
  setup() {
    const handleOpenMenuClick = () => {
      menuController.open('mainMenu');
    };

    const handleLogoClick = () => {
      router.push('/');
    };

    return {
      menu,
      handleOpenMenuClick,
      handleLogoClick,
      isReservationPage: computed(() => router.currentRoute.value.name === 'Reservation'),
      isAdminPage: computed(() => router.currentRoute.value.name === 'Admin'),
      isCreatingReservation: computed(() => !store.state.currentReservation),
      chevronBackOutline,
    }
  }
});
</script>

<style lang="scss">
$primaryColor: #0e2d63;
.TopBar {
  // background: rgba(2, 19, 49, 0.6);
  background: rgb(0 0 0 / 45%);

  ion-toolbar {
    --background: transparent;
    --min-height: 65px;
    backdrop-filter: blur(10px);
    color: #fff;
    max-width: 1300px;
    margin: auto;
  }
}

.TopBar_appTitle {
  display: inline-flex;
  align-items: center;
  margin-top: 3px;
  cursor: pointer;
}
.TopBar_appLogo {
  margin-left: 10px;
  width: 38px;
}
.TopBar_appName {
  font-weight: 500;
  font-size: 30px;
  margin-left: 18px;
}

.TopBar_pageTitle {
  margin: 0;
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.TopBar_drawerIcon {
  font-size: 55px;
  margin-right: 10px;
  cursor: pointer;
}

.TopBar_LanguageSwitch {
  margin-top: -2px;
  margin-right: 1vw;

}

.TopBar {
  .LanguageSwitch_image {
    width: 48px;

    &:hover {
      width: 44px;
      margin-right: 5px;
    }
  }
}

.TopBar_back {
  cursor: pointer;
  margin-left: 1vw;

  &.ui-btn {
    border-color: rgba(255, 255, 255, 0.25);
  }
}

.TopBar_title {
  z-index: -1;
}
</style>
