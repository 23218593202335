<template>
  <div class="DevBox ui-txt-xxs">
    <!-- Put any debugging code you need here  -->
    <pre><slot /></pre>
  </div>
</template>

<script>
export default {
  name: 'DevBox',
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
  .DevBox {
    background: rgba(20, 20, 20, 0.7);
    position: absolute;
    z-index: 10;
    overflow: auto;
    top: 65px;
    right: 0;
    bottom: 0;
    width: 450px;
    color: #fff;
    padding: 15px;
    overflow: auto;
    &:hover {
      opacity: 0.8;
    }
  }
</style>
