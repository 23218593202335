export default class EnvService {
    private buildings = {
        localhost: ['http://localhost'],
        dworcowa: [process.env.VUE_APP_DOMAIN_DWORCOWA, process.env.VUE_APP_INTERNAL_DOMAIN_DWORCOWA],
        opole: [process.env.VUE_APP_DOMAIN_OPOLE, process.env.VUE_APP_INTERNAL_DOMAIN_OPOLE],
        katowice: [process.env.VUE_APP_DOMAIN_KATOWICE, process.env.VUE_APP_INTERNAL_DOMAIN_KATOWICE],
        piwna: [process.env.VUE_APP_DOMAIN_PIWNA, process.env.VUE_APP_INTERNAL_DOMAIN_PIWNA],
    } as const;

    getCurrentBuilding(): string {
        const hostname = window.location.hostname;
        return (Object.keys(this.buildings) as (keyof typeof this.buildings)[])
            .find(key => this.buildings[key].some(domain => {
                try {
                    const url = new URL(domain);
                    return hostname === url.hostname;
                } catch (error) {
                    console.warn(`Error parsing URL for domain '${domain}':`, JSON.stringify(error, null, 2));
                    return false;
                }
            })) || 'unknown';
    }
}