import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "HomePage_content" }
const _hoisted_2 = {
  class: "HomePage_footerStart",
  slot: "start"
}
const _hoisted_3 = { class: "ui-btn_label" }
const _hoisted_4 = {
  class: "HomePage_footerEnd",
  slot: "end"
}
const _hoisted_5 = { class: "ui-btn_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_background = _resolveComponent("app-background")!
  const _component_dev_box = _resolveComponent("dev-box")!
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_day_card = _resolveComponent("day-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "HomePage" }, {
    default: _withCtx(() => [
      _createVNode(_component_app_background),
      false
        ? (_openBlock(), _createBlock(_component_dev_box, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode("store.state: " + _toDisplayString(_ctx.store.state), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_top_bar)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "HomePage_contentWrapper ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentWeek, (day, index) => {
              return (_openBlock(), _createBlock(_component_day_card, {
                key: index,
                day: day,
                button: "",
                onClick: ($event: any) => (_ctx.handleDayCardClick(day))
              }, null, 8, ["day", "onClick"]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "HomePage_footerToolbar" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (!_ctx.isGuest)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({
              'HomePage_roundBtn': true,
              'ui-btn': true,
              'is-sm': true,
              'is-black': true,
            }),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePreviousWeekClick && _ctx.handlePreviousWeekClick(...args)))
                    }, [
                      _createVNode(_component_ion_icon, {
                        class: "HomePage_arrowIcon",
                        src: _ctx.chevronBackOutline
                      }, null, 8, ["src"]),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('HomePage.previous')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_ion_title, { class: "HomePage_loadingReservations ui-txt-sm" }, {
                default: _withCtx(() => [
                  (_ctx.$store.state.isGettingReservations)
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        name: "lines"
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.getBrowsedDateRange), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                (!_ctx.isGuest)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({
              'HomePage_roundBtn': true,
              'ui-btn': true,
              'is-sm': true,
              'is-black': true,
              'is-disabled': _ctx.isNextWeekDisabled,
            }),
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleNextWeekClick && _ctx.handleNextWeekClick(...args)))
                    }, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('HomePage.next')), 1),
                      _createVNode(_component_ion_icon, {
                        class: "HomePage_arrowIcon",
                        src: _ctx.chevronForwardOutline
                      }, null, 8, ["src"])
                    ], 2))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}