<template>
  <ion-page id="ActivationPage" class="ion-page">

    <!-- app content -->
    <ion-content class="ActivationPage_content">
      <language-switch class="ActivationPage_LanguageSwitch" />
      <div class="ActivationPage_wrapper">
        <h1 class="ui-txt-lg">
        {{ $t('AwaitingActivation.awaitingActivation') }}
        </h1>

        <span
          class="AwaitingActivation_logoutBtn ui-txt-md"
          @click="handleLogoutClick"
        >
          {{$t('SideMenu.logout')}}
        </span>

      </div>
    </ion-content>
    <dev-box v-if="false">{{ store.state }}</dev-box>
  </ion-page>
</template>

<script lang="ts">
import store from '@/store/store';
import actions from '../store/actions';
import LanguageSwitch from '@/components/LanguageSwitch.vue';
import {
  IonContent,
  IonPage,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActivationPage',
  components: {
    IonContent,
    IonPage,
    LanguageSwitch,
  },
  setup() {

    // background is hidden by default probably due to animations, let's restore it on view load
    window.requestAnimationFrame(() => {
      const appBackgroundEl: HTMLElement | null = document.querySelector('.App_background');
      if (appBackgroundEl) appBackgroundEl.style.display = 'initial';
    });

    const handleLogoutClick = () => {
      actions.logout();
    };

    return {
      store,
      handleLogoutClick,
    }
  }
});
</script>

<style lang="scss">
#ActivationPage {
  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

.ActivationPage_content {
  // ...
}
.ActivationPage_LanguageSwitch {
  position: absolute;
  top: 30px;
  right: 30px;
}
.ActivationPage_wrapper {
  max-width: 960px;
  margin: 15vw auto 0 auto;
  padding: 0 30px;
  text-align: center;
}

.AwaitingActivation_logoutBtn {
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  padding: 10px 25px 15px 25px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  margin-top: calc(2vw + 20px);
  display: inline-block;
  &:hover {
    transform: scale(1.05);
  }
}
</style>
