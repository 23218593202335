import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "AdminPage_container" }
const _hoisted_2 = { class: "AdminPage_pageTitle ui-txt-xl" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "AdminPage_usersLoader"
}
const _hoisted_5 = { class: "p-column-title" }
const _hoisted_6 = { class: "p-column-title" }
const _hoisted_7 = { class: "p-column-title" }
const _hoisted_8 = { class: "p-column-title" }
const _hoisted_9 = { class: "p-column-title" }
const _hoisted_10 = { class: "p-column-title" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "AdminPage_teamsLoader"
}
const _hoisted_13 = { class: "p-column-title" }
const _hoisted_14 = { class: "p-column-title" }
const _hoisted_15 = { class: "p-column-title" }
const _hoisted_16 = { class: "p-column-title" }
const _hoisted_17 = { class: "p-column-title" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  class: "AdminPage_bookingsLoader"
}
const _hoisted_21 = { class: "p-column-title" }
const _hoisted_22 = { class: "p-column-title" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 0,
  class: "AdminPage_logsLoader"
}
const _hoisted_26 = { class: "p-column-title" }
const _hoisted_27 = { class: "p-column-title" }
const _hoisted_28 = { class: "p-column-title" }
const _hoisted_29 = { class: "p-column-title" }
const _hoisted_30 = {
  key: 0,
  style: {"color":"green"},
  class: "pi pi-check"
}
const _hoisted_31 = {
  key: 1,
  style: {"color":"red"},
  class: "pi pi-times"
}
const _hoisted_32 = { class: "p-column-title" }
const _hoisted_33 = {
  key: 0,
  class: "AdminPage_statsLoader"
}
const _hoisted_34 = { class: "ui-txt-lg" }
const _hoisted_35 = { class: "ui-txt-lg" }
const _hoisted_36 = { class: "ui-txt-lg" }
const _hoisted_37 = {
  key: 0,
  class: "AdminPage_statsLoader"
}
const _hoisted_38 = { class: "ui-txt-lg" }
const _hoisted_39 = { class: "ui-txt-lg" }
const _hoisted_40 = { class: "ui-txt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_background = _resolveComponent("app-background")!
  const _component_dev_box = _resolveComponent("dev-box")!
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_column = _resolveComponent("column")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "AdminPage" }, {
    default: _withCtx(() => [
      _createVNode(_component_app_background),
      false
        ? (_openBlock(), _createBlock(_component_dev_box, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("pre", null, "        bookings " + _toDisplayString(_ctx.bookings) + "\n      ", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_top_bar)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "12",
                      sizeLg: "8"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('AdminPage.adminPanel')), 1),
                        _createVNode(_component_ion_card, { class: "ion-padding AdminPage_navSegments" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_segment, {
                              value: _ctx.selectedSegment,
                              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSegmentChange($event)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_segment_button, {
                                  class: "AdminPage_segmentButton ui-txt-md",
                                  value: "users"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.users')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_segment_button, {
                                  class: "AdminPage_segmentButton ui-txt-md",
                                  value: "teams"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.teams')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_segment_button, {
                                  class: "AdminPage_segmentButton ui-txt-md",
                                  value: "booking"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.booking')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_segment_button, {
                                  class: "AdminPage_segmentButton ui-txt-md",
                                  value: "log"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.log')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "12",
                      sizeLg: "8",
                      class: "AdminPage_contentCol"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.selectedSegment === 'users')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.users')) + " ", 1),
                                        (_ctx.users)
                                          ? (_openBlock(), _createElementBlock("small", _hoisted_3, "(" + _toDisplayString(_ctx.users.length) + ")", 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, { class: "AdminPage_usersContent" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_searchbar, {
                                      modelValue: _ctx.userSearchPhrase,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userSearchPhrase) = $event)),
                                      color: "dark",
                                      mode: "ios",
                                      placeholder: _ctx.$t('AdminPage.search')
                                    }, null, 8, ["modelValue", "placeholder"]),
                                    (_ctx.store.state.isLoadingUsers)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                          _createVNode(_component_ion_spinner, { class: "AdminPage_usersLoader_spinner" })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_data_table, {
                                      value: _ctx.users,
                                      paginator: true,
                                      rows: 15,
                                      "data-key": "vueUuid",
                                      sortField: "username",
                                      sortOrder: 1,
                                      removableSort: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_column, {
                                          field: "username",
                                          header: _ctx.$t('AdminPage.name'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('AdminPage.name')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.firstName) + " " + _toDisplayString(slotProps.data.lastName) + " " + _toDisplayString(slotProps.data.username), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "roles",
                                          header: _ctx.$t('AdminPage.roles'),
                                          style: {"width":"25%"},
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('AdminPage.roles')), 1),
                                            _createVNode(_component_ion_select, {
                                              onIonChange: ($event: any) => (_ctx.handleUserRolesChange($event.detail.value, slotProps.data.username, slotProps.data.roles)),
                                              multiple: "",
                                              value: slotProps.data.roles,
                                              "ok-text": _ctx.$t('AdminPage.accept'),
                                              "cancel-text": _ctx.$t('AdminPage.cancel')
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allAvailableRoles, (roleItem, index) => {
                                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                                    value: roleItem.role,
                                                    key: index,
                                                    disabled: _ctx.isRoleOptionDisabled(roleItem, slotProps.data.username, slotProps.data.roles)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(roleItem.fullName), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value", "disabled"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onIonChange", "value", "ok-text", "cancel-text"])
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "teams",
                                          header: _ctx.$t('AdminPage.teams'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('AdminPage.teams')), 1),
                                            _createVNode(_component_ion_select, {
                                              placeholder: _ctx.$t('AdminPage.noTeam'),
                                              onIonChange: ($event: any) => (_ctx.handleUserTeamsChange($event.detail.value, slotProps.data.username)),
                                              value: slotProps.data.teams,
                                              "ok-text": _ctx.$t('AdminPage.accept'),
                                              "cancel-text": _ctx.$t('AdminPage.cancel'),
                                              disabled: !_ctx.IsTeamSelectEnabled(slotProps.data.roles)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamOptions, (teamItem, index) => {
                                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                                    value: teamItem.team,
                                                    key: index
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(teamItem.fullName), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["placeholder", "onIonChange", "value", "ok-text", "cancel-text", "disabled"])
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "parkPlace",
                                          header: _ctx.$t('AdminPage.space'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('AdminPage.space')), 1),
                                            _createVNode(_component_ion_select, {
                                              onIonChange: ($event: any) => (_ctx.handleUserPlaceChange($event.detail.value, slotProps.data)),
                                              value: slotProps.data.spaceNumber,
                                              "ok-text": _ctx.$t('AdminPage.accept'),
                                              "cancel-text": _ctx.$t('AdminPage.cancel'),
                                              placeholder: _ctx.$t('AdminPage.pickPlace'),
                                              disabled: !_ctx.IsParkingSpaceSelectEnabled(slotProps.data.roles)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parkingSpaces, (spaceItem, index) => {
                                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                                    value: spaceItem.number,
                                                    key: index,
                                                    disabled: _ctx.isParkingSpaceDisabled(spaceItem, slotProps.data, index)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(spaceItem.number ? spaceItem.number : _ctx.$t('AdminPage.noParkingPlace')) + " " + _toDisplayString(spaceItem.ownership ? `(${spaceItem.ownership.ownerId})` : null), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value", "disabled"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onIonChange", "value", "ok-text", "cancel-text", "placeholder", "disabled"])
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "registrationNumber",
                                          header: _ctx.$t('AdminPage.carRegNumber'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('AdminPage.carRegNumber')), 1),
                                            _createVNode(_component_ion_input, {
                                              onIonFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.lastFocusedValue = $event.detail.target.value)),
                                              onIonBlur: ($event: any) => (_ctx.handleUserRegNumChange($event.detail.target.value, slotProps.data.username)),
                                              onKeyup: _cache[3] || (_cache[3] = _withKeys($event => $event.target.blur(), ["enter"])),
                                              value: slotProps.data.registrationNumber
                                            }, null, 8, ["onIonBlur", "value"])
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          header: _ctx.$t('AdminPage.actions')
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('AdminPage.actions')), 1),
                                            _createVNode(_component_ion_button, {
                                              size: "small",
                                              color: "danger",
                                              onClick: ($event: any) => (_ctx.handleDeleteUserClick(slotProps.data.username))
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.delete')), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ]),
                                          _: 1
                                        }, 8, ["header"])
                                      ]),
                                      _: 1
                                    }, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.selectedSegment === 'teams')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.teams')) + " ", 1),
                                        (_ctx.allAvailableTeams)
                                          ? (_openBlock(), _createElementBlock("small", _hoisted_11, "(" + _toDisplayString(_ctx.allAvailableTeams.length) + ")", 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, {
                                  class: "AdminPage_teamsContent",
                                  style: {"color":"#fff"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_searchbar, {
                                      modelValue: _ctx.teamSearchPhrase,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.teamSearchPhrase) = $event)),
                                      color: "dark",
                                      mode: "ios",
                                      placeholder: _ctx.$t('AdminPage.search')
                                    }, null, 8, ["modelValue", "placeholder"]),
                                    _createVNode(_component_ion_row, null, {
                                      default: _withCtx(() => [
                                        (_ctx.store.state.isLoadingTeams)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                              _createVNode(_component_ion_spinner, { class: "AdminPage_teamsLoader_spinner" })
                                            ]))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_data_table, {
                                          value: _ctx.allAvailableTeams,
                                          class: "p-datatable-responsive-demo",
                                          paginator: true,
                                          rows: 15,
                                          "data-key": "vueUuid",
                                          sortField: "team",
                                          sortOrder: 1,
                                          removableSort: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_column, {
                                              field: "team",
                                              header: _ctx.$t('AdminPage.name'),
                                              sortable: true
                                            }, {
                                              body: _withCtx((slotProps) => [
                                                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('AdminPage.name')), 1),
                                                _createElementVNode("p", null, _toDisplayString(slotProps.data.team), 1)
                                              ]),
                                              _: 1
                                            }, 8, ["header"]),
                                            _createVNode(_component_column, {
                                              field: "fullName",
                                              header: _ctx.$t('AdminPage.fullName'),
                                              sortable: true
                                            }, {
                                              body: _withCtx((slotProps) => [
                                                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('AdminPage.fullName')), 1),
                                                _createVNode(_component_ion_input, {
                                                  onIonFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.lastFocusedValue = $event.detail.target.value)),
                                                  onIonBlur: ($event: any) => (_ctx.handleTeamFullNameChange($event.detail.target.value, slotProps.data.team)),
                                                  onKeyup: _cache[6] || (_cache[6] = _withKeys($event => $event.target.blur(), ["enter"])),
                                                  value: slotProps.data.fullName
                                                }, null, 8, ["onIonBlur", "value"])
                                              ]),
                                              _: 1
                                            }, 8, ["header"]),
                                            _createVNode(_component_column, {
                                              field: "description",
                                              header: _ctx.$t('AdminPage.description'),
                                              sortable: true
                                            }, {
                                              body: _withCtx((slotProps) => [
                                                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('AdminPage.description')), 1),
                                                _createVNode(_component_ion_input, {
                                                  onIonFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.lastFocusedValue = $event.detail.target.value)),
                                                  onIonBlur: ($event: any) => (_ctx.handleTeamDescriptionChange($event.detail.target.value, slotProps.data.team)),
                                                  onKeyup: _cache[8] || (_cache[8] = _withKeys($event => $event.target.blur(), ["enter"])),
                                                  value: slotProps.data.description
                                                }, null, 8, ["onIonBlur", "value"])
                                              ]),
                                              _: 1
                                            }, 8, ["header"]),
                                            _createVNode(_component_column, {
                                              field: "spaces",
                                              header: _ctx.$t('AdminPage.spaces'),
                                              sortable: true
                                            }, {
                                              body: _withCtx((slotProps) => [
                                                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('AdminPage.spaces')), 1),
                                                _createVNode(_component_ion_input, {
                                                  onIonFocus: _cache[9] || (_cache[9] = ($event: any) => (_ctx.lastFocusedValue = $event.detail.target.value)),
                                                  onIonBlur: ($event: any) => (_ctx.handleTeamSpacesChange($event.detail.target.value, slotProps.data.team)),
                                                  onKeyup: _cache[10] || (_cache[10] = _withKeys($event => $event.target.blur(), ["enter"])),
                                                  value: slotProps.data.count
                                                }, null, 8, ["onIonBlur", "value"])
                                              ]),
                                              _: 1
                                            }, 8, ["header"]),
                                            _createVNode(_component_column, {
                                              header: _ctx.$t('AdminPage.actions')
                                            }, {
                                              body: _withCtx((slotProps) => [
                                                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('AdminPage.actions')), 1),
                                                _createVNode(_component_ion_button, {
                                                  size: "small",
                                                  color: "danger",
                                                  onClick: ($event: any) => (_ctx.handleDeleteTeamClick(slotProps.data.team))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.delete')), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 1
                                            }, 8, ["header"])
                                          ]),
                                          _: 1
                                        }, 8, ["value"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_card_title, { class: "ion-padding-top" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.createTeam')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_col, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_item, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_input, {
                                                  modelValue: _ctx.createdTeamName,
                                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.createdTeamName) = $event)),
                                                  placeholder: _ctx.$t('AdminPage.name')
                                                }, null, 8, ["modelValue", "placeholder"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_col, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_item, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_input, {
                                                  modelValue: _ctx.createdTeamFullName,
                                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.createdTeamFullName) = $event)),
                                                  placeholder: _ctx.$t('AdminPage.fullName')
                                                }, null, 8, ["modelValue", "placeholder"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_col, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_item, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_input, {
                                                  modelValue: _ctx.createdTeamDescription,
                                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.createdTeamDescription) = $event)),
                                                  placeholder: _ctx.$t('AdminPage.description')
                                                }, null, 8, ["modelValue", "placeholder"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_col, { size: "2" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_button, {
                                              size: "medium",
                                              expand: "block",
                                              color: "success",
                                              disabled: !_ctx.isCreateTeamAvailable,
                                              onClick: _ctx.handleCreateTeamClick
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.create')), 1)
                                              ]),
                                              _: 1
                                            }, 8, ["disabled", "onClick"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.selectedSegment === 'booking')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 2 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.booking')) + " ", 1),
                                        (_ctx.bookings)
                                          ? (_openBlock(), _createElementBlock("small", _hoisted_18, [
                                              _createTextVNode("( " + _toDisplayString(_ctx.bookings.length) + " ", 1),
                                              (_ctx.enrichedBookings)
                                                ? (_openBlock(), _createElementBlock("small", _hoisted_19, " of " + _toDisplayString(_ctx.enrichedBookings.length), 1))
                                                : _createCommentVNode("", true),
                                              _createTextVNode(" )")
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, { class: "AdminPage_bookingContent" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_grid, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { class: "ion-float-left" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_searchbar, {
                                                  modelValue: _ctx.bookingSearchPhrase,
                                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.bookingSearchPhrase) = $event)),
                                                  color: "dark",
                                                  mode: "ios",
                                                  placeholder: _ctx.$t('AdminPage.search')
                                                }, null, 8, ["modelValue", "placeholder"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { class: "ion-float-left" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_datetime_button, {
                                                  mode: "ios",
                                                  datetime: "datetime",
                                                  color: "light",
                                                  style: {"position":"relative","top":"30px","right":"-140px"}
                                                }),
                                                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_datetime, {
                                                      "show-default-buttons": true,
                                                      "first-day-of-week": 1,
                                                      id: "datetime",
                                                      presentation: "date",
                                                      onIonChange: _ctx.onChangeBookingDate
                                                    }, null, 8, ["onIonChange"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.store.state.isLoadingBookings)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          _createVNode(_component_ion_spinner, { class: "AdminPage_bookingsLoader_spinner" })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_data_table, {
                                      value: _ctx.enrichedBookings,
                                      paginator: true,
                                      rows: 25,
                                      "data-key": "vueUuid",
                                      sortField: "number",
                                      sortOrder: 1,
                                      removableSort: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_column, {
                                          field: "number",
                                          header: _ctx.$t('AdminPage.space'),
                                          style: {"width":"25%"},
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('AdminPage.space')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.number), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "booking.bookingUserId",
                                          header: _ctx.$t('AdminPage.name'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('AdminPage.name')), 1),
                                            (slotProps.data.booking)
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createTextVNode(_toDisplayString(slotProps.data.booking.bookingUserId) + " ", 1),
                                                  (slotProps.data.booking.bookingTeamId)
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                        _createTextVNode(" (" + _toDisplayString(slotProps.data.booking.bookingTeamId) + ") ", 1)
                                                      ], 64))
                                                    : _createCommentVNode("", true)
                                                ], 64))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 1
                                        }, 8, ["header"])
                                      ]),
                                      _: 1
                                    }, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.selectedSegment === 'log')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 3 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.log')) + " ", 1),
                                        (_ctx.logs)
                                          ? (_openBlock(), _createElementBlock("small", _hoisted_23, [
                                              _createTextVNode("( " + _toDisplayString(_ctx.logs.length) + " ", 1),
                                              (_ctx.enrichedLogs)
                                                ? (_openBlock(), _createElementBlock("small", _hoisted_24, " of " + _toDisplayString(_ctx.enrichedLogs.length), 1))
                                                : _createCommentVNode("", true),
                                              _createTextVNode(" )")
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, { class: "AdminPage_bookingContent" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_grid, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { class: "ion-float-left" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_searchbar, {
                                                  modelValue: _ctx.logsSearchPhrase,
                                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.logsSearchPhrase) = $event)),
                                                  color: "dark",
                                                  mode: "ios",
                                                  placeholder: _ctx.$t('AdminPage.search')
                                                }, null, 8, ["modelValue", "placeholder"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { class: "ion-float-left" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_datetime_button, {
                                                  mode: "ios",
                                                  datetime: "datetime",
                                                  color: "light",
                                                  style: {"position":"relative","top":"30px","right":"-140px"}
                                                }),
                                                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_datetime, {
                                                      "show-default-buttons": true,
                                                      "first-day-of-week": 1,
                                                      id: "datetime",
                                                      presentation: "date",
                                                      onIonChange: _ctx.onChangeLogDate
                                                    }, null, 8, ["onIonChange"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.store.state.isLoadingLogs)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                          _createVNode(_component_ion_spinner, { class: "AdminPage_logsLoader_spinner" })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_data_table, {
                                      value: _ctx.enrichedLogs,
                                      paginator: true,
                                      rows: 25,
                                      "data-key": "vueUuid",
                                      sortField: "number",
                                      sortOrder: 1,
                                      removableSort: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_column, {
                                          field: "userId",
                                          header: _ctx.$t('AdminPage.name'),
                                          style: {"width":"25%"},
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('AdminPage.name')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.userId), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "placeNumber",
                                          header: _ctx.$t('AdminPage.space'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('AdminPage.space')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.placeNumber), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "team",
                                          header: _ctx.$t('AdminPage.teams'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('AdminPage.teams')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.team), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "action",
                                          header: _ctx.$t('AdminPage.action'),
                                          sortable: false
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('AdminPage.action')), 1),
                                            (slotProps.data.action == 'RESERVE')
                                              ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                                              : _createCommentVNode("", true),
                                            (slotProps.data.action != 'RESERVE')
                                              ? (_openBlock(), _createElementBlock("i", _hoisted_31))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 1
                                        }, 8, ["header"]),
                                        _createVNode(_component_column, {
                                          field: "actionDate",
                                          header: _ctx.$t('AdminPage.actionDate'),
                                          sortable: true
                                        }, {
                                          body: _withCtx((slotProps) => [
                                            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('AdminPage.actionDate')), 1),
                                            _createTextVNode(" " + _toDisplayString(slotProps.data.actionDate), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["header"])
                                      ]),
                                      _: 1
                                    }, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "12",
                      sizeLg: "4",
                      class: "AdminPage_statsCol"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.selectedSegment === 'users')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.parkingSpacesSummary')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, null, {
                                  default: _withCtx(() => [
                                    (_ctx.store.state.isLoadingSpacesSummary)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                          _createVNode(_component_ion_spinner, { class: "AdminPage_statsLoader_spinner" })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_grid, { class: "AdminPage_usersSummary" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.totalPool')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_34, _toDisplayString(_ctx.spacesSummary.totalCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.assignedToVipTl')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_35, _toDisplayString(_ctx.spacesSummary.withOwnerCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.remainingPool')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_36, _toDisplayString(_ctx.spacesSummary.withoutOwnerCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.selectedSegment === 'teams')
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('AdminPage.spacesSummary')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_card_content, null, {
                                  default: _withCtx(() => [
                                    (_ctx.store.state.isLoading)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                          _createVNode(_component_ion_spinner, { class: "AdminPage_statsLoader_spinner" })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_grid, { class: "AdminPage_spacesSummary" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.remainingPool')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_38, _toDisplayString(_ctx.spacesSummary.totalTeamPlacesCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.assignedSpaces')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_39, _toDisplayString(_ctx.spacesSummary.assignedToTeamsCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, { size: "4" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('AdminPage.availableSpaces')) + ":", 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, { size: "8" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", null, [
                                                  _createElementVNode("strong", _hoisted_40, _toDisplayString(_ctx.remainingTeamPlacesCount), 1)
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}