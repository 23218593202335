import { API_BASE_URL } from './baseUrl';
import { IDay, IReservation } from '@/store/interfaces';
import moment from 'moment';
import axios from 'axios'
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 1 });
import errorHandler, { ICustomError, responseHandler } from './errorHandler';

// setting headers https://masteringjs.io/tutorials/axios/authorization
export const reservationAxios = axios.create({
  baseURL: API_BASE_URL + '/api/parking',
});

const DAY_DATE_FORMAT = 'YYYY-MM-DD';

interface IParkingSpaceDTO {
  number: number;
  floor: number;
}

interface IReservationDTO {
  from: string;
  to: string;
  bookingUserId: string;
  bookingTeamId: string;
  parkingSpace: IParkingSpaceDTO;
}

function reservationDTOReducer(
  map: Map<string, IReservation>, reservationDTO: IReservationDTO,
): Map<string, IReservation> {
  const dateFrom = moment(reservationDTO.from).format(DAY_DATE_FORMAT);
  const parkingSpaceDTO = reservationDTO.parkingSpace;
  const reservation: IReservation = {
    level: parkingSpaceDTO.floor,
    place: parkingSpaceDTO.number,
  };
  map.set(dateFrom, reservation);
  return map;
}

class ReservationService {
  loadWeek = async (
      startOfTheWeek: moment.Moment,
    ): Promise<IDay[] | ICustomError> => {
    try {
      const reservations: IReservationDTO[] = [];

      const dayToReservations = reservations
        .reduce(reservationDTOReducer, new Map<string, IReservation>());

      // @DEV this should be moved out of service
      // returns array of 7 days with empty reservations
      const days: IDay[] = [];
      for (let i = 0; i < 7; i++) {
        const date = startOfTheWeek.clone().add(i, 'day');
        const reservation = dayToReservations.get(date.format(DAY_DATE_FORMAT));
        const day: IDay = {
          date,
          freeParkingSpacesCount: 0,
          reservation, // undef
          reservations: [],
        };
        days.push(day);
      }

      return days;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error loading week content.');
    }
  }

  getFreeParkingSpaces = async (
    from: string,
    to: string,
    isShowingNotifications = true,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/free-parking-spaces';
    const queryParams = {
      from, to,
    }
    try {
      const response = (await reservationAxios.get(reqUrl, { params: queryParams })).data;
      return responseHandler(response, 'Parking service returned no free parking spaces.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting free parking spaces.', isShowingNotifications);
    }
  }

  getTeamParkingSpace = async (): Promise<any | ICustomError> => {
    const reqUrl = '/team-parking-spaces';
    try {
      const response = (await reservationAxios.get(reqUrl)).data;
      return responseHandler(response, 'Parking service returned no team parking spaces.');
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting team parking spaces.');
    }
  }

  /**
   * Returns list of all parking spaces without ownership information
   */
  getParkingSpaces = async (): Promise<any | ICustomError> => {
    const reqUrl = '/parking-spaces';
    try {
      const response = (await reservationAxios.get(reqUrl)).data;
      return responseHandler(response, 'Parking service returned no parking spaces.');
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting parking spaces.');
    }
  }

  deleteParkingSpaceOwnership = async (
    parkingSpaceNumber: string,
  ): Promise<any | ICustomError> => {
    const reqUrl = `/ownerships?parkingSpaceNumber=${parkingSpaceNumber}`;
    try {
      const response = (await reservationAxios.delete(reqUrl));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error deleting parking space ownership.');
    }
  }

  setParkingSpaceOwnerships = async (
    ownerId: string | null,
    ownerTeamId: string | null,
    parkingSpaceNumber: number
  ): Promise<any | ICustomError> => {
    const reqUrl = `/ownerships?parkingSpaceNumber=${parkingSpaceNumber}`;
    const reqBody = {
      ownerId,
      ownerTeamId,
    };
    try {
      const response = (await reservationAxios.put(reqUrl, reqBody)).data;
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error setting parking space owner.');
    }
  }

  /**
   * Returns list of assignments between parking space and owner
   */
  getParkingSpaceOwnerships = async (): Promise<any | ICustomError> => {
    const reqUrl = '/ownerships';
    try {
      const response = (await reservationAxios.get(reqUrl)).data;
      // if (!response) responseHandler(response, 'Parking service returned no parking space ownerships.')
      // return response;
      return responseHandler(response, 'Parking service returned no parking space ownerships.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting parking space ownerships.');
    }
  }

  getUserParkingSpacesSummary = async (): Promise<any | ICustomError> => {
    const reqUrl = '/ownerships/summary';
    try {
      const response = (await reservationAxios.get(reqUrl)).data;
      return responseHandler(response, 'Parking service returned no user parking spaces summary.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting user parking spaces summary.');
    }
  }

  getAdminReservations = async (
    from: string,
    to: string,
    isShowingNotifications = true,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/reservations/admin';
    const queryParams = {
      from, to,
    }
    try {
      const response = (await reservationAxios.get(reqUrl, { params: queryParams })).data;
      return responseHandler(response, 'Parking service returned no reservation list.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting reservation list.', isShowingNotifications);
    }
  }
  getAdminLogs = async (
      date: string,
      isShowingNotifications = true,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/log';
    const queryParams = {
      date,
    }
    try {
      const response = (await reservationAxios.get(reqUrl, { params: queryParams })).data;
      return responseHandler(response, 'Parking service returned no logs list.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting log list.', isShowingNotifications);
    }
  }
  getReservations = async (
    from: string,
    to: string,
    isShowingNotifications = true,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/reservations';
    const queryParams = {
      from, to,
    }
    try {
      const response = (await reservationAxios.get(reqUrl, { params: queryParams })).data;
      return responseHandler(response, 'Parking service returned no reservation list.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting reservation list.', isShowingNotifications);
    }
  }

  saveReservation = async (
    reservationDate: string,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/reservations';
    const reqBody = {
      reservationDate
    }
    try {
      const response = (await reservationAxios.post(reqUrl, reqBody)).data;
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error saving reservation.');
    }
  }

  deleteReservation = async (
    resId: string,
  ): Promise<any | ICustomError> => {
    const reqUrl = `/reservations/${resId}`;
    try {
      const response = (await reservationAxios.delete(reqUrl));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error deleting reservation.');
    }
  }

  getTeamParkingSpaces = async (): Promise<any | ICustomError> => {
    const reqUrl = '/team-parking-spaces';
    try {
      const response = await reservationAxios.get(reqUrl);
      return responseHandler(response, 'Parking service returned no team parking space list.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting team parking space list.');
    }
  }

  getTeamParkingSpacesSummary = async (): Promise<any | ICustomError> => {
    const reqUrl = '/team-parking-spaces/summary';
    try {
      const response = (await reservationAxios.get(reqUrl)).data;
      return responseHandler(response, 'Parking service returned no team parking space summary.');
    } catch (error) {
      return errorHandler(error as any, 'Parking service error getting team parking space summary.');
    }
  }

  updateTeamParkingSpace = async (
    count: number,
    teamId: string,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/team-parking-spaces';
    const reqBody = {
      count,
      teamId,
    };
    try {
      const response = (await reservationAxios.put(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Parking service error updating team parking space.');
    }
  }
}

export default new ReservationService();
