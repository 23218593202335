import axios from 'axios'
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 1 });
import errorHandler, { ICustomError, responseHandler } from './errorHandler';
import { API_BASE_URL } from './baseUrl';

export const authorizationAxios = axios.create({
  baseURL: API_BASE_URL + '/api/user',
});
class AuthorizationService {

  getRoles = async (): Promise<any | ICustomError> => {
    const reqUrl = '/role';
    try {
      const response = (await authorizationAxios.get(reqUrl)).data.content;
      return responseHandler(response, 'Authorization service returned no role list.');
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error getting role list.');
    }
  }

  getUserSession = async (): Promise<any | ICustomError> => {
    const reqUrl = '/session';
    try {
      const response = (await authorizationAxios.get(reqUrl)).data;
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service couldn\'t get user session.');
    }
  }

  saveRole = async (
    // ...
  ): Promise<any | ICustomError> => {
    const reqUrl = '/role';
    const reqBody = {
      // ...
    };
    try {
      const response = (await authorizationAxios.post(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error saving role.');
    }
  }

  updateRole = async (
    // ...
  ): Promise<any | ICustomError> => {
    const reqUrl = '/role';
    const reqBody = {
      // ...
    };
    try {
      const response = (await authorizationAxios.put(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error updating role.');
    }
  }

  getTeams = async (): Promise<any | ICustomError> => {
    const itemsPerResponse = 99999;
    const reqUrl = `/team?size=${itemsPerResponse}`;
    try {
      const response = (await authorizationAxios.get(reqUrl)).data.content;
      return responseHandler(response, 'Authorization service returned no team list.');
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error getting team list.');
    }
  }

  saveTeam = async (
    // ...
  ): Promise<any | ICustomError> => {
    const reqUrl = '/team';
    const reqBody = {
      // ...
    };
    try {
      const response = (await authorizationAxios.post(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error saving team.');
    }
  }

  updateTeam = async (
    newTeam: any,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/team';
    const reqBody = newTeam;
    try {
      const response = (await authorizationAxios.put(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error updating team: ' + newTeam.team + '.');
    }
  }

  createTeam = async (
    description: string,
    fullName: string,
    team: string
  ): Promise<any | ICustomError> => {
    const reqUrl = '/team';
    const reqBody = {
      description,
      fullName,
      team,
    };
    try {
      const response = (await authorizationAxios.post(reqUrl, reqBody));
      return response;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return errorHandler(error as any, '', false);
      }
      return errorHandler(error as any, 'Authorization service error creating team: ' + fullName + '.');
    }
  }

  /**
   * Returns list of all users without some specific data like user owned parking spaces
   */
  getUsers = async (): Promise<any | ICustomError> => {
    const itemsPerResponse = 99999;
    const reqUrl = `/user?size=${itemsPerResponse}`;
    try {
      const response = (await authorizationAxios.get(reqUrl)).data.content;
      return responseHandler(response, 'Authorization service returned no user list.');
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error getting user list.');
    }
  }

  saveUser = async (
    // ...
  ): Promise<any | ICustomError> => {
    const reqUrl = '/user';
    const reqBody = {
      // ...
    };
    try {
      const response = (await authorizationAxios.post(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error saving user.');
    }
  }

  updateUser = async (
    newUser: any,
  ): Promise<any | ICustomError> => {
    const reqUrl = '/user';
    const reqBody = newUser;
    try {
      const response = (await authorizationAxios.put(reqUrl, reqBody));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error updating user: ' + newUser.username + '.');
    }
  }

  deleteUser = async (
    username: any,
  ): Promise<any | ICustomError> => {
    const reqUrl = `/user/${username}`;
    try {
      const response = (await authorizationAxios.delete(reqUrl));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error deleting user.');
    }
  }

  deleteTeam = async (
    team: string,
  ): Promise<any | ICustomError> => {
    let encodedTeam = encodeURIComponent(team);
    // encode reserved chars (based on: https://www.urlencoder.org/)
    encodedTeam = encodedTeam
      .replace('!', '%21').replace('#', '%23').replace('$', '%24').replace('&', '%26')
      .replace("'", '%27').replace('(', '%28').replace(')', '%29').replace('*', '%2A')
      .replace('+', '%2B').replace(',', '%2C').replace('/', '%2F').replace(':', '%3A')
      .replace(';', '%3B').replace('=', '%3D').replace('?', '%3F').replace('@', '%40')
      .replace('[', '%5B').replace(']', '%5D')
    const reqUrl = `/team/${encodedTeam}`;
    try {
      const response = (await authorizationAxios.delete(reqUrl));
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Authorization service error deleting team.');
    }
  }
}

export default new AuthorizationService();
