import { AxiosError } from 'axios';
import eventsService from '@/services/events.service';
import { store } from '@/store/store';
export interface ICustomError {
  isError: boolean;
  message: string;
  description: string;
  error: AxiosError | Error;
  status: number;
}

export function responseHandler(
  responseData: unknown,
  description: string,
  isDataRequired = true,
): any {
  // show warning notification
  if (isDataRequired && !responseData) {
    eventsService.emit('ADD_TOAST', {
      severity: 'warn',
      detail: description,
      life: 5000,
    });
  }
  return responseData;
}

export default function errorHandler(
  error: AxiosError,
  description: string,
  isShowingNotifications = true,
): ICustomError {
  const customError = {
    isError: true,
    message: '',
    description: '',
    status: 0,
    error,
  };

  const isNotificationSilenced = false;
  // error case descriptions from:
  // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    // destructure response from error
    const { response } = error;

    // extract status from response
    customError.status = response.status;

    // extract message from response
    if (typeof response.data === 'string') {
      customError.message = response.data;
    } else {
      customError.message = response.data.message || response.data.error || response.statusText;
    }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    // server did not respond
    customError.message = 'Error contacting server';
  } else {
    // Something happened in setting up the request and triggered an Error
    // no request was sent due to axios error
    customError.message = 'Could not send request';
  }

  if (description) customError.description = description;

  // @TODO replace 502 with 460 error code
  if (customError.status && customError.status === 460) {
    store.commit('SET_IS_ACCOUNT_AWAITING_ACTIVATION', true);
  }

  if (isShowingNotifications) {
    console.warn(error);

    // show error notification
    if (!isNotificationSilenced) {
      eventsService.emit('ADD_TOAST', {
        severity: 'error',
        summary: customError.description || 'no error title',
        detail: customError.message || 'no error details',
        life: 5000,
      });
    }
  }


  return customError;
}
