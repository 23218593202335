import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import { init, mountedInit } from '@/init';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Primevue */
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';

/* Global style */
import './common/style.scss';

/* Application modules */
import { store } from "./store/store";
import DevBox from './components/DevBox.vue';
import i18n from '@/plugins/i18n';

// create app
const app = createApp(App)

// init before mounted
init(() => {
  app.use(IonicVue, { mode: 'ios' });
  app.use(PrimeVue);
  app.use(ToastService);
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.component('DevBox', DevBox);

  router.isReady().then(async () => {
    // mount app
    app.mount('#app').$nextTick(async () => {
      // init after mounted
      await mountedInit();
    });
  });

});

export default app;