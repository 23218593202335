<template>
  <div :class="componentClass">
    <div class="AppBgr_container">
      <figure class="AppBgr_slide is-primary">
        <div class="AppBgr_slidePhoto">photo 1</div>
      </figure>
      <figure class="AppBgr_slide is-secondary">
        <div class="AppBgr_slidePhoto">photo 2</div>
      </figure>
      <figure class="AppBgr_slide is-tertiary">
        <div class="AppBgr_slidePhoto">photo 3</div>
      </figure>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import EnvService from '@/services/env.service';
const envService = new EnvService();

export default defineComponent({
  name: 'AppBackground',
  components: {
  },
  setup() {
    const componentClass = computed(() => {
      const currentBldg = envService.getCurrentBuilding();

      return {
        'AppBgr': true,
        'is-bldg-localhost': currentBldg === 'localhost',
        'is-bldg-dworcowa': currentBldg === 'dworcowa',
        'is-bldg-piwna': currentBldg === 'piwna',
        'is-bldg-opole': currentBldg === 'opole',
        'is-bldg-katowice': currentBldg === 'katowice',
      };
    });

    return {
      componentClass
    };
  }
});
</script>

<style lang="scss">
.AppBgr {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: #fff;
  background-size: cover;
  background-position: center;
}

@keyframes animatedBgr {
  0% {background-color: #003cb9;}
  50% {background-color: #0078ff;}
  100% {background-color: #003cb9;}
}

.AppBgr {
  height: 100%;
  overflow: hidden;
  // animation: animatedBgr 5s infinite;
  background: linear-gradient(135deg, darken(#003cb9, 20%) 0%, darken(#0078ff, 15%) 100%);
  filter: saturate(1.2);
  &.is-green {
    filter: saturate(1.3);
    background: linear-gradient(135deg, #007855 0%, #84b400 100%);
  }
//     background: linear-gradient(135deg
// , hsl(162deg 100% 27%) 0%, hsl(76deg 100% 40%) 100%);
}

.AppBgr_container {
  position: relative;
  height: 100%;
  margin: 0 -14vh;
}

.AppBgr_slide {
  position: absolute;
  width: calc(1/3 * 100%);
  float: left;
  height: 100%;
  transform: skewX(-14deg);
  overflow: hidden;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background:
      linear-gradient(to right, rgba(255, 239, 196, 0) 0%, rgba(255, 239, 196, 0) 55%, rgb(255 239 196 / 10%) 100%),
      linear-gradient(to right, rgba(0, 0, 0, .17) 0%, rgba(0, 0, 0, .1) 4%, rgba(0, 0, 0, 0) 17%);
  }

  &.is-primary {
    left: 0;
  }

  &.is-secondary {
    left: calc(1/3 * 100%);
  }

  &.is-tertiary {
    left: calc(2/3 * 100%);
  }
}

.is-bldg-dworcowa {
  .AppBgr_slide.is-primary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-dworcowa-1.jpg'); }
  .AppBgr_slide.is-secondary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-dworcowa-2.jpg'); }
  .AppBgr_slide.is-tertiary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-dworcowa-3.jpg'); }
}

.is-bldg-piwna {
  .AppBgr_slide.is-primary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-piwna-1.jpg'); }
  .AppBgr_slide.is-secondary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-piwna-2.jpg'); }
  .AppBgr_slide.is-tertiary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-piwna-3.jpg'); }
}
.is-bldg-localhost,
.is-bldg-opole {
  .AppBgr_slide.is-primary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-opole-1.jpg'); }
  .AppBgr_slide.is-secondary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-opole-2.jpg'); }
  .AppBgr_slide.is-tertiary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-opole-3.jpg'); }
}

.is-bldg-katowice {
  .AppBgr_slide.is-primary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-katowice-1.jpg'); }
  .AppBgr_slide.is-secondary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-katowice-2.jpg'); }
  .AppBgr_slide.is-tertiary .AppBgr_slidePhoto { background-image: url('@/../public/photos/mainbgr-katowice-3.jpg'); }
}

.AppBgr_slidePhoto {
  height: 100%;
  transform: skewX(14deg);
  margin: 0 -14vh;
  opacity: 0.25;
  background-size: cover;
  background-position: center;
  filter: saturate(0);
  position: relative;
}
</style>

