/**
 * Helper function for api requests
 * Returns API Base url from .env file depending on current environment
 */

// extract required env variables
// temporarily hardcoded config for test deployment
const {
  NODE_ENV,
  VUE_APP_API_BASE_URL,
  VUE_APP_API_PROXY_PORT,
  VUE_APP_API_PROXY_URL,
} = process.env

export const API_BASE_URL : string = NODE_ENV === 'production'
? VUE_APP_API_BASE_URL
: `${VUE_APP_API_PROXY_URL}:${VUE_APP_API_PROXY_PORT}`;