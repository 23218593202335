<template>
  <ion-page id="LoginPage" :class="componentClass">
    <!-- app content -->
    <dev-box v-if="false">store.state: {{ store.state }}</dev-box>
    <!-- ion-content can't have background image -->
    <ion-content>
      <div class="LoginPage_wrapper">
        <div class="LoginPage_asideHeader">
          <div class="LoginPage_ista">
            <img src="/assets/ista-logo-white.svg" alt="Ista logo" />
          </div>
          <div>
            <language-switch class="LoginPage_LanguageSwitch" />
          </div>
        </div>

        <div class="LoginPage_container">
          <header class="LoginPage_mainHeader">
            <img src="/assets/logo/logo-svg-for-export.svg" class="LoginPage_appLogo">
            <h1 class="LoginPage_title ui-txt-xl">Park.it</h1>
          </header>

          <form @keyup.enter="handleLoginClick" class="LoginPage_form">
            <div class="LoginPage_stripe">
              <div class="LoginPage_stripeDark"></div>
            </div>

            <div class="LoginPage_header">
              <div class="LoginPage_headerWrapper">
                <div class="LoginPage_headerContent">
                  <h3 class="LoginPage_headerText">{{ loginHeader }}</h3>
                </div>
              </div>
            </div>

            <ion-grid class="LoginPage_formContent">
              <!-- buttons -->
              <ion-row class="ion-padding-top">
                <ion-col>
                  <ion-item lines="none" v-if="!isLoggedIn">
                    <span
                      :class="{
                        'LoginPage_submitButton': true,
                        'is-disabled': isLoggingInOut,
                      }"
                      @click="handleLoginClick"
                    >
                      {{ $t('LoginPage.loginButton') }}
                    </span>
                  </ion-item>
                  <ion-item lines="none" v-if="isLoggedIn">
                    <span
                      @click="handleLogoutClick"
                      :class="{
                        'LoginPage_submitButton': true,
                        'is-disabled': isLoggingInOut,
                      }"
                    >
                      {{ $t('LoginPage.logout') }}
                    </span>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </div>
      </div>
    </ion-content>
    <dev-box v-if="false">{{ store.state }}</dev-box>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonItem } from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';
import store from '@/store/store';
import LanguageSwitch from '@/components/LanguageSwitch.vue';
import DevBox from '@/components/DevBox.vue';
import AuthService from '@/services/auth.service';
import EnvService from '@/services/env.service';

const authService = new AuthService();
const envService = new EnvService();

export default defineComponent({
  name: 'LoginPage',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    LanguageSwitch,
    DevBox,
  },
  setup() {
    const isLoggingInOut = ref(false);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const handleLoginClick = async () => {
      isLoggingInOut.value = true;
      await authService.login();
    };
    const handleLogoutClick = async () => {
      isLoggingInOut.value = true;
      await authService.logout();
    };

    const componentClass = computed(() => {
      const currentBldg = envService.getCurrentBuilding();
      return {
        'LoginPage_content': true,
        'is-bldg-localhost': currentBldg === 'localhost',
        'is-bldg-dworcowa': currentBldg === 'dworcowa',
        'is-bldg-piwna': currentBldg === 'piwna',
        'is-bldg-opole': currentBldg === 'opole',
        'is-bldg-katowice': currentBldg === 'katowice',
      };
    });

    const loginHeader = computed(() => {
      const currentBldg = envService.getCurrentBuilding();
      switch (currentBldg) {
        case 'localhost': return 'Localhost';
        case 'dworcowa': return 'Gliwice';
        case 'opole': return 'Opole';
        case 'piwna': return 'Piwna';
        case 'katowice': return 'Katowice';
        default: return 'Login';
      }
    });

    return {
      handleLoginClick,
      handleLogoutClick,
      isLoggedIn,
      isLoggingInOut,
      store,
      componentClass,
      loginHeader
    };
  },
});
</script>

<style lang="scss">
$primaryColor: #0e2d63;
// remove default spacing
#LoginPage {
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: transparent;
    --background-hover: transparent;
  }
  ion-col {
    padding: 0;
  }
  ion-button {
    margin: 0;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #113;
  }

  &.is-bldg-piwna {
    .LoginPage_wrapper {
      background: url('@/../public/photos/login-bgr-halfwide-piwna.jpg') center;
      background-size: cover;
    }
  }

  &.is-bldg-opole {
    .LoginPage_wrapper {
      background: url('@/../public/photos/login-bgr-opole-2.jpg') center;
      background-size: cover;
    }
  }

  &.is-bldg-katowice {
    .LoginPage_wrapper {
      background: url('@/../public/photos/login-bgr-katowice-2.jpg') center;
      background-size: cover;
    }
  }


  &.is-bldg-dworcowa {
    .LoginPage_wrapper {
      background: url('@/../public/photos/login-bgr-halfwide_3.jpg') center;
      background-size: cover;
    }
  }

  &.is-bldg-localhost {
    .LoginPage_wrapper {
      background: url('@/../public/photos/login-bgr-opole-2.jpg') center;
      background-size: cover;
    }
  }
}

.LoginPage_asideHeader {
  position: absolute;
  top: calc(15px + 2.5vw);
  right: 15px;
  z-index: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.LoginPage_ista {
  img {
    width: calc(65px + 4vw);
  }
}

.LoginPage_LanguageSwitch {
  overflow: hidden;
  border: solid 1px #ffffff36;
  border-radius: 2px;
  background: rgb(0 38 97 / 50%);
  backdrop-filter: blur(5px);
  padding: 0 3px;
  margin-top: 0px;
  margin-right: 15px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
}

.LoginPage_wrapper {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  // background: url('@/../public/photos/login-bgr-halfwide.jpg') center;
  background-size: cover;
  padding: 0 15px;
  min-width: 320px;
  @media screen and (max-width: 1024px) {
    // overflow-y: visible;
  }
}

.LoginPage_content {
  --ion-background-color:#fafafa;
}

.LoginPage_container {
  max-width: 530px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 2.5vw;
  --ion-background-color: transparent;
  position: relative;
  z-index: 0;
}

.LoginPage_mainHeader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: calc(11vw - 200px);
  margin-top: 10px;
}

.LoginPage_appLogo {
  display: block;
  width: 10vw;
  max-width: 130px;
  min-width: 70px;
}

.LoginPage_title {
  color: #0e2d63;
  text-shadow: 0 0 30px #dde0e5;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0;
  position: relative;
  top: 1.3vw;
  left: -0.5vw;
}

.LoginPage_form {
  position: relative;
  background: #fff;
  padding: 0;
  margin-top: calc(10px + 4vw);
}

.LoginPage_header {
  height: 270px;
  margin-right: -1px;
  margin-left: -1px;
}

.LoginPage_headerWrapper {
  transform: skewY(-23deg);
  transform-origin: 0;
  height: 100%;
  overflow: hidden;
}

.LoginPage_headerContent {
  transform: skewY(23deg);
  transform-origin: 0;
  height: 100%;
  background: url('@/../public/photos/login-header-photo-1200.jpg') center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: saturate(130%);
}

.LoginPage_headerText {
  margin: -50px 0 0 50px;
  font-size: 48px;
  color: #fff;
  font-weight: 100;
}

.LoginPage_formContent {
  margin-top: -50px;
  padding: 40px;
}

.LoginPage_showHidePassword {
  text-align: right;
  color: $primaryColor;
  user-select: none;
  margin-right: 17px;
  margin-bottom: 0;
  cursor: pointer;
}

.LoginPage_stripe {
  width: calc(100vw + 2px);
  top: -20px;
  left: -1px;
  bottom: -30px;
  margin: auto;
  margin-left: 50%;
  position: absolute;
  left: 0;
  z-index: -1;
  transform: skewY(-23deg) translateX(-50%);
  transform-origin: 0;
  display: flex;
  flex-direction: column;
  background: rgb(11,41,96);
  background: linear-gradient(108deg, rgba(11,41,96,1) 0%, rgba(17,65,126,1) 100%);
  opacity: 0.88;
}

.LoginPage_stripeDark {
  height: 177px;
  background: #0b2e65
}

.LoginPage_reference {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  // opacity: 1;
  // opacity: 0.5;
  // opacity: 0.1;
  opacity: 0;
  pointer-events: none;
}

.LoginPage_submitButton {
  text-transform: uppercase;
  font-weight: 500;
  color: #163b7d;
  font-size: 22px;
  display: inline-block;
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 40px;
  line-height: 38px;
  border: solid 5px #133d86;
  transition: all 0.1s;
  margin: auto;
  &:hover {
    color: darken(#163b7d, 5%);
    background: rgba(235, 245, 255);
    border-color: darken(#133d86, 10%);
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
    filter: saturate(0.75);
  }
}
</style>
