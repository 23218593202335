// moved emitter to shared service to use the same
// instance of mitt across the app app.
// more info:
// https://github.com/developit/mitt
// https://v3.vuejs.org/guide/migration/events-api.html#migration-strategy
// ps. also used primefaces/toast requires mitt as devDependency

import mitt from 'mitt';
const emitter = mitt(); 
export default emitter;
