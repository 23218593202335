<template>
  <ion-page id="UiPage">
    <ion-header>
      header
    </ion-header>

    <ion-content class="ion-padding">
      <p class="UiPage_sectionTitle">rwd font sizes (px presented for large screens)</p>
      <p class="ui-txt-xxs">Text size xxs 15px</p>
      <p class="ui-txt-xs">Text size xs 19px</p>
      <p class="ui-txt-sm">Text size sm 24px</p>
      <p class="ui-txt-md">Text size md 30px</p>
      <p class="ui-txt-lg">Text size lg 44px</p>
      <p class="ui-txt-xl">Text size xl 72px</p>
      <p class="ui-txt-xxl">Text size xxl 90px</p>
      <p class="ui-txt-xxxl">Text size xxxl 140px</p>
      <p class="UiPage_sectionTitle">Buttons</p>
      <p class="ui-btn is-md is-black">
        <i class="fas fa-home"></i>
        Black button
      </p>
      <p class="ui-btn is-md is-black is-animated">
        <i class="fas fa-home"></i>
        Hover animated button
      </p>

      Icon button
      <span class="ui-btn is-sm is-black is-animated">
        <ion-icon :src="chevronBackOutline"></ion-icon>
        <span class="ui-btn_label">Back</span>
      </span>

      <span class="ui-btn is-sm is-black is-animated">
        <span class="ui-btn_label">Next</span>
        <ion-icon :src="chevronForwardOutline"></ion-icon>
      </span>

      <p class="ui-btn is-xxs">
        <i class="fas fa-home"></i>
        Button xxs 15px
      </p>
      <p class="ui-btn is-xs">
        <i class="fas fa-home"></i>
        Button xs 19px
      </p>
      <p class="ui-btn is-sm">
        <i class="fas fa-home"></i>
        Button sm 24px
      </p>
      <p class="ui-btn is-md">
        <i class="fas fa-home"></i>
        Button md 30px
      </p>
      <p class="ui-btn is-lg">
        <i class="fas fa-home"></i>
        Button lg 44px
      </p>
      <p class="ui-btn is-xl">
        <i class="fas fa-home"></i>
        Button xl 72px
      </p>
      <p class="ui-btn is-xxl">
        <i class="fas fa-home"></i>
        Button xxl 90px
      </p>
      <p class="ui-btn is-xxxl">
        <i class="fas fa-home"></i>
        Button xxxl 140px
      </p>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { menu } from 'ionicons/icons';
import chevronForwardOutline from 'ionicons/dist/svg/chevron-forward-outline.svg';
import chevronBackOutline from 'ionicons/dist/svg/chevron-back-outline.svg';

export default defineComponent({
  name: 'UiPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
  },
  setup() {
    return {
      menu,
      chevronBackOutline,
      chevronForwardOutline,
    }
  }
});
</script>

<style lang="scss">
@import '/src/common/helpers';

#UiPage {
  background: #ddd;
}

.UiPage_sectionTitle {
  border-bottom: solid 2px #333;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 18px;
  line-height: 32px;
  padding: 30px 10px 0 10px;
}

.ui-txt-xxs {
  font-size: uiscale(14px, 15px);
  line-height: uiscale(21px, 22px);
}
.ui-txt-xs {
  font-size: uiscale(16px, 19px);
  line-height: uiscale(24px, 27px);
}
.ui-txt-sm {
  font-size: uiscale(18px, 24px);
  line-height: uiscale(26px, 30px);
}
.ui-txt-md {
  font-size: uiscale(20px, 30px);
  line-height: uiscale(28px, 40px);
}
.ui-txt-lg {
  font-size: uiscale(22px, 44px);
  line-height: uiscale(30px, 55px);
}
.ui-txt-xl {
  font-size: uiscale(24px, 72px);
  line-height: uiscale(32px, 90px);
}
.ui-txt-xxl {
  font-size: uiscale(26px, 90px);
  line-height: uiscale(34px, 108px);
}
.ui-txt-xxxl {
  font-size: uiscale(28px, 140px);
  line-height: uiscale(36px, 140px);
}

.ui-btn {
  border: solid #fff;
  backdrop-filter: blur(8px);
  font-weight: 500;
  border-radius: 100vh;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 0 0.65em;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .fas {
    margin: 0 0.1em;
  }
  &.is-stretched {
    width: 100%;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  &.is-black {
    background: rgba(0 ,0, 0, 0.5);
    color: #fff;
    border-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }
  &.is-animated {
    transition: transform .25s;
    &:hover {
      transform: scale(1.1);
    }
  }

  ion-icon {
    font-size: 1.5em;
    margin: 0 -0.3em;
  }

  ion-icon + .ui-btn_label {
    margin-left: 0.6em;
  }

  .ui-btn_label + ion-icon {
    margin-left: 0;
  }
}

.ui-btn.is-xxs {
  font-size: uiscale(14px, 15px);
  line-height: uiscale(21px*1.3, 22px*1.3);
  border-width: 1px;
}
.ui-btn.is-xs {
  font-size: uiscale(16px, 19px);
  line-height: uiscale(24px*1.3, 27px*1.3);
  border-width: uiscale(1.25px, 2px);
}
.ui-btn.is-sm {
  font-size: uiscale(18px, 24px);
  line-height: uiscale(26px*1.3, 30px*1.3);
  border-width: uiscale(1.5px, 3px);
}
.ui-btn.is-md {
  font-size: uiscale(20px, 30px);
  line-height: uiscale(28px*1.3, 40px*1.3);
  border-width: uiscale(1.75px, 4px);
}
.ui-btn.is-lg {
  font-size: uiscale(22px, 44px);
  line-height: uiscale(30px*1.3, 55px*1.3);
  border-width: uiscale(2px, 5px);
}
.ui-btn.is-xl {
  font-size: uiscale(24px, 72px);
  line-height: uiscale(32px*1.3, 90px*1.3);
  border-width: uiscale(2.25px, 6px);
}
.ui-btn.is-xxl {
  font-size: uiscale(26px, 90px);
  line-height: uiscale(34px*1.3, 108px*1.3);
  border-width: uiscale(2.5px, 7px);
}
.ui-btn.is-xxxl {
  font-size: uiscale(28px, 140px);
  line-height: uiscale(36px*1.3, 140px*1.3);
  border-width: uiscale(2.75px, 8px);
}
</style>
