<template>
  <ion-app>
    <!-- background -->
    <app-background class="App_background" style="display: none" />

    <!-- isAccountAwaitingActivation: {{ $store.state.isAccountAwaitingActivation }} -->

    <awaiting-activation v-if="store.state.isAccountAwaitingActivation" />
    <template v-else>
      <!-- side menu -->
      <side-menu v-if="isLoggedIn" />

      <!-- page -->
      <ion-router-outlet id="main" />
    </template>

    <dev-box v-if="false">
      isAccountAwaitingActivation: {{ store.state.isAccountAwaitingActivation }}<br>
    </dev-box>
    
    <!-- primevue toast -->
    <toast
      position="top-center"
      @click="handleToastClick"
    />
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';
import SideMenu from '@/components/SideMenu.vue';
import store from './store/store';
import { useToast } from "primevue/usetoast";
import eventsService from '@/services/events.service';
import Toast from 'primevue/toast';
import AppBackground from '@/components/AppBackground.vue';
import AwaitingActivation from './views/AwaitingActivation.vue';
import router from './router';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    SideMenu,
    Toast,
    AppBackground,
    AwaitingActivation,
  },
  setup() {
    // @TODO better code for maintenance mode
    const isMaintenance = ref(false);
    if(isMaintenance.value && !localStorage.getItem('GOD_MODE')) router.push('/callback');

    // show notification toast
    const toast = useToast();
    eventsService.on('ADD_TOAST', toastOptions => {
      // https://www.primefaces.org/primevue/showcase/#/toast
      let detail = toastOptions.detail;
      if (detail && detail.length > 120) toastOptions.detail = detail.slice(0, 120-1) + '...';
      toast.add(toastOptions);
    });

    // close toast on click
    const handleToastClick = function (evt: any) {
      const closeButtonEl = evt.target.querySelector('.p-toast-icon-close');
      if (closeButtonEl) closeButtonEl.click();
    }

    return {
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      handleToastClick,
      store,
    }
  },
});
</script>
