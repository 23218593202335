<template>
  <ion-page id="CallbackPage">
    <div class="CallbackPage_content">
      <div class="lds-heart"><div></div></div>
      <h1 class="CallbackPage_maintenance" v-if="isMaintenance">Park.it is under maintenance<br>we'll come back soon...</h1>
      <div class="CallbackPage_loginPanel" v-if="isLoginPanelVisible && !isMaintenance">
        <h3>Logging in takes too long?</h3>
        <p>Try repeating the process with following steps:</p>
        <p>1. Logout from previous session:
          <span class="CallbackPage_link" @click="handleSoftLogoutClick">
            Soft logout
          </span> or
          <span class="CallbackPage_link" @click="handleForceLogoutClick">
            Force logout
          </span>
        </p>
        <p>2. Login to a new session:
          <span class="CallbackPage_link" @click="handleLoginClick">
            Login
          </span> or back to
          <span  class="CallbackPage_link" @click="handleHomeClick">
            Home Screen
          </span>
        </p>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import AuthService from '@/services/auth.service';
import router from '@/router/index';
import { store } from '@/store/store';
const auth = new AuthService();
export default defineComponent({
  name: 'CallbackPage',
  components: {
    IonPage,
  },
  setup() {
    // @TODO move it to env vars
    // @TODO better code for maintenance mode
    // switch it if you want to turn on maintenance
    const isMaintenance = ref(false);
    if(localStorage.getItem('GOD_MODE')) isMaintenance.value = false;

    const handleRedirectCallback = () => {
      auth.userManager.signinRedirectCallback().then(function () {
        window.location.href = '../';
      }).catch(function (err) {
        // @TODO consider "No matching state found in storage" error triggering logout
        console.info('We have error in signingRedirectCallback:', err);
      });
    }

    const isLoginPanelVisible = ref(false);
    setTimeout(() => {
      isLoginPanelVisible.value = true;
    }, 4000);

    const handleSoftLogoutClick = () => {
      auth.logout();
    }
    const handleForceLogoutClick = () => {
      window.location.href = 'https://fs.ista.com/adfs/ls/?wa=wsignoutcleanup1.0';
    }
    const handleLoginClick = () => {
      auth.login();
    }
    const handleHomeClick = () => {
      router.push('/home');
    }
    if (store.getters.isLoggedIn) {
      // case: already logged in
      router.push('/home');
    }

    return {
      handleRedirectCallback,
      handleSoftLogoutClick,
      handleForceLogoutClick,
      handleLoginClick,
      handleHomeClick,
      isMaintenance,
      isLoginPanelVisible,
    }
  },
  async mounted () {
    const {code, state} = this.$route.query;
    if (code && state) this.handleRedirectCallback();
  },
});
</script>

<style lang="scss">
#CallbackPage {
  width: 100vw;
  height: 100vh;
  display: block;
  background: #05418f;
  color: #fff;
}
.CallbackPage_loginPanel {
  line-height: 1.5em;
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 10px 40px 20px 40px;
  margin-top: 20px;
  border-radius: 10px;
}
.CallbackPage_link {
  font-size: 1.25em;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  &:hover {
    border-bottom-color: #fff;
    color: #fff;
  }
}
.CallbackPage_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fff;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.CallbackPage_maintenance {
  color: #fff;
  text-align: center;
}
</style>
