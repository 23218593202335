import axios from 'axios'
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 1 });
import errorHandler, { ICustomError, responseHandler } from './errorHandler';

import { API_BASE_URL } from './baseUrl';

export const aggregatorAxios = axios.create({
  baseURL: API_BASE_URL + '/api/aggregator',
});
class AggregatorService {
  getTeams = async (): Promise<any | ICustomError> => {
    const reqUrl = '/teamSpace';
    try {
      const response = (await aggregatorAxios.get(reqUrl)).data.content;
      return responseHandler(response, 'Aggregator service returned no team list.');
      return response;
    } catch (error) {
      return errorHandler(error as any, 'Aggregator service error getting aggregated team list.');
    }
  }
}

export default new AggregatorService();
