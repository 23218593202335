<template>
  <span class="LanguageSwitch">
    <img class="LanguageSwitch_image"
      :src="currentLangObj.icon"
      @click="handleLanguageClick"
    />
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import plSvg from 'svg-country-flags/svg/pl.svg';
import gbSvg from 'svg-country-flags/svg/gb.svg';
import deSvg from 'svg-country-flags/svg/de.svg';
import actions from '@/store/actions';
import store from '@/store/store';

export default defineComponent({
  name: 'LanguageSwitch',
  components: {
  },
  setup() {
    const currentLang = computed(() => store.state.user.language);
    const languages = ref([
      {
        lang: 'en',
        icon: gbSvg,
      },
      {
        lang: 'pl',
        icon: plSvg,
      }, {
        lang: 'de',
        icon: deSvg,
      }]
    );

    const languageIconSrc = computed(() => {
      if (currentLang.value === 'pl') {
        return plSvg;
      } else {
        return gbSvg;
      }
    });

    const handleLanguageClick = function () {
      const currentFlagIndex = languages.value.findIndex(l => l.lang === currentLang.value)
      if (currentFlagIndex > -1 && currentFlagIndex < languages.value.length - 1 ) {
        // go to next lang
        actions.setLanguage(languages.value[currentFlagIndex + 1].lang);
      } else {
        // go back to first
        actions.setLanguage(languages.value[(Object as any).keys(languages.value)[0]].lang);
      }
    }

    const getCurrentLang = computed(() => {
      let languageObject = languages.value.find(l => currentLang.value === l.lang);
      if (!languageObject) languageObject = languages.value[(Object as any).keys(languages.value)[0]];
      return languageObject;
    });

    return {
      languageIconSrc,
      handleLanguageClick,
      currentLangObj: getCurrentLang,
    }
  }
});
</script>

<style lang="scss">
.LanguageSwitch {
  display: inline-block;
}
.LanguageSwitch_image {
  width: 37px;
  height: 30px;
  margin: 1px 3px 0 3px;
  display: block;
  cursor: pointer;
  &:hover {
    width: 43px;
    margin: 0;
  }
}
</style>