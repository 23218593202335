<template>
  <ion-header>
    <ion-toolbar class="ParkingMapModal_toolbar">
      <ion-title class="ParkingMapModal_title">
        <span class="ui-txt-md">{{ $t('ParkingMapModal.parkingMap') }}: {{ $t('ParkingMapModal.floor') }} {{ floorNumber }}</span>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button class="ParkingMapModal_close" @click="handleCloseModalClick()">
          <ion-icon :icon="close" size="large" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="ParkingMapModal_content">
      <div
        @click="handleMapClick"
        :class="{
          'ParkingMapModal_content_scroll': true,
          'is-expanded': isMapExpanded,
        }"
      >
        <div class="ParkingMapModal_centering">
          <img
            :src="getFloorImageUrl"
            class="ParkingMapModal_mapImage"
          >
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController, IonIcon, IonButton, IonButtons } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { close } from 'ionicons/icons';
import EnvService from '@/services/env.service';

const envService = new EnvService();

export default defineComponent({
  name: 'ParkingMapModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
  },
  setup() {
    const store = useStore();
    const isMapExpanded = ref(false);
    const floorNumber = computed(() => store.state.currentReservation.parkingSpace.floor);
    const handleCloseModalClick = () => {
      modalController.dismiss();
    };

    const handleMapClick = () => {
      isMapExpanded.value = !isMapExpanded.value;
    };

    const getFloorImageUrl = computed(() => {
      const floorImages = {
        localhost: {
          '-1': 'parking_opole_map.png',
          '0': 'parking_map_level_0.jpg',
          '+0.5': 'parking_map_level_05.jpg',
          '1': 'parking_map_level_1.jpg',
          '2': 'parking_map_level_2.jpg'
        },
        piwna: {
          '-1': 'parking_piwna_map_level_-1.jpg',
          '0': 'parking_piwna_map_level_0.jpg',
          '+0.5': 'parking_piwna_map_level_0.jpg',
          '1': 'parking_piwna_map_level_0.jpg',
          '2': 'parking_piwna_map_level_0.jpg'
        },
        opole: {
          '0': 'parking_opole_map.png'
        },
        dworcowa: {
          '0': 'parking_map_level_0.jpg',
          '+0.5': 'parking_map_level_05.jpg',
          '1': 'parking_map_level_1.jpg',
          '2': 'parking_map_level_2.jpg'
        },
        katowice: {},
      };

      const currentBldg = envService.getCurrentBuilding();

      const imagesForcurrentBldg = floorImages[currentBldg] || floorImages['localhost']; // default to 'localhost' if no match
      const selectedFloorImage = imagesForcurrentBldg[floorNumber.value] || 'default_image.jpg'; // default image if no floor match
      return `/assets/${selectedFloorImage}`;
    });

    return {
      handleCloseModalClick,
      getFloorImageUrl,
      floorNumber,
      close,
      handleMapClick,
      isMapExpanded
    };
  },
});
</script>
<style lang="scss">
.ParkingMapModal_mapImage {
  pointer-events: none;
  user-select: none;
  transition: all 0.5s;
  min-width: 100vw;
  width: 100%;
  display: block;
}

.ParkingMapModal_content_scroll {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  cursor: zoom-in;
  background: #fff;
  &.is-expanded {
    cursor: zoom-out;
    overflow: scroll;
    .ParkingMapModal_mapImage {
      width: 1900px;
      max-width: 200vw;
    }
    // fix invisible scroll on mac
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 20px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }
    &::-webkit-scrollbar-track { 
      background-color: #fff;
    }
  }
}

.ParkingMapModal_centering {
  margin-top: auto;
  margin-bottom: auto;
}

.ParkingMapModal_content {
  backdrop-filter: blur(5px);
  display: flex;
  height: 100%;
  background: #206e37;
}


.ParkingMapModal_toolbar {
  color: #fff;
  height: 65px;
  backdrop-filter: blur(5px);
  --background: #294f2e;
}

.ParkingMapModal_title {
  position: relative;
  height: 65px;
  margin-top: -3px;
}

.ParkingMapModal_close {
  ion-icon {
    color: #fff;
  }
}

</style>
